import {
    Button,
    Form,
    FormInstance,
    InputRef,
    Select,
    Input,
    InputNumber,
    Popconfirm,
    Skeleton,
} from 'antd';
import { CustomNotification, Table, Text } from 'components/basic';
import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import Field from 'components/basic/Field/Field';
import moment from 'moment';
import './FareConfig.module.scss';
import useApi from 'hooks/useApi';
import { saveFareConfig } from 'services/message.service';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { formatNumberToAmount, getOrdinalIndicator } from 'services/number.service';
import classnames from 'classnames';
import { AuthUserContext } from 'components/context/AuthUserContext';
import { Accessibility, PagesEnum, getPermissions } from 'services/permission.service';

const EditableContext = React.createContext<FormInstance<any> | null>(null);

interface Item {
    key: string;
    start_value: string;
    end_value: string;
    price: string;
}

interface EditableRowProps {
    index: number;
}

const EditableRow: React.FC<EditableRowProps> = ({ index, ...props }) => {
    const [form] = Form.useForm();
    return (
        <Form form={form} component={false}>
            <EditableContext.Provider value={form}>
                <tr {...props} />
            </EditableContext.Provider>
        </Form>
    );
};

interface EditableCellProps {
    title: React.ReactNode;
    editable: boolean;
    children: React.ReactNode;
    dataIndex: keyof Item;
    record: Item;
    handleSave: (record: Item) => void;
}

const EditableCell: React.FC<EditableCellProps> = ({
    title,
    editable,
    children,
    dataIndex,
    record,
    handleSave,
    ...restProps
}) => {
    const [editing, setEditing] = useState(false);
    const inputRef = useRef<HTMLInputElement | null>(null);
    const form = useContext(EditableContext)!;

    useEffect(() => {
        if (editing) {
            inputRef.current!.focus();
        }
    }, [editing]);

    const toggleEdit = () => {
        setEditing(!editing);
        form.setFieldsValue({ [dataIndex]: record[dataIndex] });
    };

    const save = async () => {
        try {
            const values = await form.validateFields();

            toggleEdit();
            handleSave({ ...record, ...values });
        } catch (errInfo) {
            console.log('Save failed:', errInfo);
        }
    };

    let childNode = children;

    if (editable) {
        childNode = editing ? (
            <Form.Item
                style={{ margin: 0 }}
                name={dataIndex}
                initialValue={parseFloat(record[dataIndex])}
                rules={[
                    {
                        required: true,
                        message: `${title} is required.`,
                    },
                ]}
            >
                <InputNumber
                    type="number"
                    min={0}
                    ref={inputRef}
                    onPressEnter={save}
                    onBlur={save}
                    style={{ width: '100%' }}
                />
            </Form.Item>
        ) : (
            <div
                className="editable-cell-value-wrap"
                style={{
                    padding: '3px 7px',
                    border: '1px solid #d9d9d9',
                    borderRadius: '2px',
                }}
                onClick={toggleEdit}
            >
                {children}
            </div>
        );
    }

    return <td {...restProps}>{childNode}</td>;
};

export const formatFareConfigErrorMessage = (errorMessage: string) => {
    const parts: string[] = errorMessage?.split(' ');

    parts[0] = parts[0].charAt(0).toUpperCase() + parts[0].slice(1);

    const keywordIndex = parts.indexOf('distance_based_price');
    if (keywordIndex !== -1) {
        parts[keywordIndex] = `- Distance Based Prices`;
    }

    parts.forEach((part, index) => {
        const number = parseInt(part);
        if (!isNaN(number)) {
            const ordinalIndicator = getOrdinalIndicator(number);
            parts[index] = `${number}${ordinalIndicator} row`;
        }
    });

    return `${parts.join(' ')}.`;
};

const FareConfigServiceType = (props: any) => {
    const [isEditable, setIsEditable] = useState(props.onCreate || false);
    const [form] = Form.useForm();
    const [dataSource, setDataSource] = useState<any[]>(
        props?.fare?.distance_based_price || []
    );
    const userRole = useContext(AuthUserContext);
    const hasActionAccess = (page: string) => {
        return getPermissions(userRole, page)?.includes(Accessibility.ALL) || getPermissions(userRole, page)?.includes(Accessibility.EDIT)
    }

    const components = {
        body: {
            row: EditableRow,
            cell: EditableCell,
        },
    };

    const columnsRaw = [
        {
            title: 'Start in meters (m)',
            dataIndex: 'start_value',
            key: 'start_value',
            editable: true,
            width: '40%',
        },
        {
            title: 'End in meters (m)',
            dataIndex: 'end_value',
            key: 'end_value',
            editable: true,
            width: '40%',
        },
        {
            title: 'Price (Php)',
            dataIndex: 'price',
            key: 'price',
            editable: true,
            width: '20%',
        },
    ];

    const handleSave = (row: Item) => {
        if (isEditable) {
            const newData = dataSource.map((row, index) => {
                return {
                    ...row,
                    key: index,
                };
            });
            const index = newData.findIndex((item) => row.key === item.key);
            const item = newData[index];
            newData.splice(index, 1, {
                ...item,
                ...row,
            });
            setDataSource(newData);
        }
    };

    const handleAdd = () => {
        const newData: any = {
            start_value: '0',
            end_value: '0',
            price: '0',
            key: editableDataSource.length,
        };
        setDataSource([...editableDataSource, newData]);
    };

    const editableColumns = columnsRaw.map((col) => {
        if (!col.editable) {
            return col;
        }
        return {
            ...col,
            onCell: (record: any) => ({
                record,
                editable: col.editable,
                dataIndex: col.dataIndex,
                title: col.title,
                handleSave,
            }),
        };
    });

    const { request, loading } = useApi({
        api: saveFareConfig,
    });

    const onFinish = async (values: any) => {
        const isValid = await form.validateFields();
        console.log(isValid);
        let body = {
            ...values,
            id: props.fare.id,
            base_price: parseFloat(values.base_price),
            driver_commission: parseFloat(values.driver_commission),
            service_type: props.fare.service_type,
            service_zone: props.fare.service_zone,
            distance_based_price: dataSource.map((data) => {
                const { start_value, end_value, price } = data;
                return {
                    start_value: parseFloat(start_value),
                    end_value: parseFloat(end_value),
                    price: parseFloat(price),
                };
            }),
        };

        const result = await request({ body });
        if (!result.error) {
            setIsEditable(!isEditable);
            if (props.refreshList) {
                await props.refreshList();
            }
            CustomNotification({
                type: 'success',
                message: 'Success',
                description: 'Successfully Saved',
            });
        } else if (result.error.customMessage) {
            CustomNotification({
                type: 'error',
                message: 'Error',
                description: formatFareConfigErrorMessage(result.error.customMessage),
            });
        } else {
            CustomNotification({
                type: 'error',
                message: 'Error',
                description: result?.error.message,
            });
        }
    };

    const onFinishFailed = (errorInfo: any) => {
        console.log('Failed:', errorInfo);
    };

    const handleDelete = (key: React.Key) => {
        const newData = editableDataSource.filter((item) => item.key !== key);
        setDataSource(newData);
    };

    const editableDataSource = useMemo(() => {
        return (
            dataSource?.map((row, index) => {
                return {
                    ...row,
                    key: index,
                };
            }) || []
        );
    }, [dataSource]);

    const handleCancel = () => {
        onReset();
        setDataSource(props?.fare?.distance_based_price);
        setIsEditable(false);
    };

    const onReset = () => {
        form.resetFields();
    };

    const handleCreate = async () => {
        try {
            await form.validateFields();
            if (props.handleCreate) {
                const values = form.getFieldsValue();
                let body = {
                    ...values,
                    base_price: parseFloat(values.base_price),
                    driver_commission: parseFloat(values.driver_commission),
                    distance_based_price: dataSource?.map((data) => {
                        const { start_value, end_value, price } = data;
                        return {
                            start_value: parseFloat(start_value),
                            end_value: parseFloat(end_value),
                            price: parseFloat(price),
                        };
                    }),
                };
                props.handleCreate(body);
            }
        } catch (error) {
            console.log(error);
        }
    };

    const formatServiceType = (service_type: string): string => {
        const serviceMappings: Record<string, string> = {
            padala_bike: 'Padala',
            passenger_bike: 'Passenger',
            passenger_four_seater: 'Passenger Four Seater',
            passenger_six_seater: 'Passenger Six Seater'
        };

        const formattedService = serviceMappings[service_type];

        if (formattedService !== undefined) {
            return formattedService;
        } else {
            return 'Invalid service type';
        }
    };

    return (
        <div
            className={classnames(
                'rounded gap-2 flex flex-col',
                props.inModal ? '' : 'p-4 border border-dashed border-slate-100'
            )}
        >
            <Form
                key={props.index}
                form={form}
                initialValues={{
                    version: props.fare?.version_id || props.initialVersion,
                    service_zone: props.fare?.service_zone,
                    base_price: props.fare?.base_price,
                    driver_commission: props.fare?.driver_commission,
                    service_type: props.fare?.service_type,
                }}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                autoComplete="off"
            >
                <div className="flex gap-2 items-center mb-4">
                    {!isEditable && hasActionAccess(PagesEnum.GENERAL_SETTINGS) && (
                        <Button
                            size="small"
                            onClick={() => {
                                setIsEditable(!isEditable);
                                setDataSource(props?.fare?.distance_based_price);
                            }}
                        >
                            Edit
                        </Button>
                    )}
                    {isEditable && !props.onCreate && (
                        <div className="flex gap-2">
                            <Button size="small" loading={loading} onClick={handleCancel}>
                                Cancel
                            </Button>
                            <Button
                                size="small"
                                htmlType="submit"
                                type="primary"
                                loading={loading}
                            >
                                Save
                            </Button>
                        </div>
                    )}
                    {!isEditable && (
                        <p className="m-0">
                            Last updated at:{' '}
                            <span className="font-semibold">
                                {props.fare?.updated_at
                                    ? moment.utc(props.fare?.updated_at).add(8, 'hour').format('MMMM DD, YYYY hh:mm:ss A')
                                    : '-' || '-'}
                            </span>
                        </p>
                    )}
                </div>

                <div className="flex gap-8">
                    <div className="w-96">
                        {!isEditable ? (
                            <div className="flex-1 flex flex-row gap-8">
                                <Field
                                    label="Base Price (PHP)"
                                    value={
                                        props.fare?.driver_commission
                                            ? formatNumberToAmount(props.fare?.base_price)
                                            : '-'
                                    }
                                />
                                <Field
                                    label="Commission Rate (%)"
                                    value={
                                        props.fare?.driver_commission
                                            ? formatNumberToAmount(
                                                  props.fare?.driver_commission
                                              )
                                            : '-'
                                    }
                                />
                            </div>
                        ) : (
                            <div className="flex-1 flex flex-row gap-4">
                                <div>
                                    <Text type="label">Base Price (PHP)</Text>
                                    <Form.Item
                                        name="base_price"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Field is required',
                                            },
                                            {
                                                type: 'number',
                                                message: 'Must be a number',
                                                transform: (value) => {
                                                    if (
                                                        value !== undefined &&
                                                        value !== ''
                                                    ) {
                                                        const parsedValue =
                                                            parseFloat(value);
                                                        if (
                                                            isNaN(parsedValue) ||
                                                            value !==
                                                                parsedValue.toString()
                                                        ) {
                                                            return NaN; // Return NaN for non-numeric input
                                                        }
                                                        return parsedValue;
                                                    }

                                                    return parseFloat(value);
                                                },
                                            },
                                        ]}
                                    >
                                        <Input
                                            value={props.fare?.base_price}
                                            prefix={
                                                <span className="text-gray uppercase">
                                                    Php
                                                </span>
                                            }
                                        />
                                    </Form.Item>
                                </div>
                                <div>
                                    <Text type="label">Commission Rate (%)</Text>
                                    <Form.Item
                                        name="driver_commission"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Field is required',
                                            },
                                        ]}
                                    >
                                        <Input
                                            value={props.fare?.driver_commission}
                                            prefix={
                                                <span className="text-gray uppercase">
                                                    Php
                                                </span>
                                            }
                                        />
                                    </Form.Item>
                                </div>
                            </div>
                        )}
                    </div>
                    <div className="flex-1 gap-4">
                        {loading ? (
                            <Skeleton />
                        ) : (
                            <div className="flex flex-col gap-2">
                                <p className={isEditable ? 'm-0' : 'm-0'}>
                                    Distance Based Prices
                                </p>
                                {!isEditable ? (
                                    <Table
                                        columns={columnsRaw}
                                        pagination={false}
                                        dataSource={props.fare?.distance_based_price}
                                    ></Table>
                                ) : (
                                    <div className="flex flex-col">
                                        <Table
                                            columns={[
                                                ...editableColumns,
                                                {
                                                    title: 'Operation',
                                                    dataIndex: 'operation',
                                                    render: (
                                                        _,
                                                        record: { key: React.Key }
                                                    ) =>
                                                        dataSource.length >= 1 ? (
                                                            <Popconfirm
                                                                title="Confirm to delete?"
                                                                onConfirm={() =>
                                                                    handleDelete(
                                                                        record.key
                                                                    )
                                                                }
                                                                okText="Yes"
                                                                okType="danger"
                                                                icon={
                                                                    <QuestionCircleOutlined
                                                                        style={{
                                                                            color: 'red',
                                                                        }}
                                                                    />
                                                                }
                                                            >
                                                                <a className="text-red-500 hover:text-red-600">
                                                                    Delete
                                                                </a>
                                                            </Popconfirm>
                                                        ) : null,
                                                },
                                            ]}
                                            rowClassName={() => 'editable-row'}
                                            dataSource={editableDataSource}
                                            components={components}
                                            pagination={false}
                                        />
                                        <Button
                                            onClick={handleAdd}
                                            size="small"
                                            className="mt-sm"
                                        >
                                            Add new row
                                        </Button>
                                    </div>
                                )}
                            </div>
                        )}
                    </div>
                </div>
            </Form>

            {props.onCreate && (
                <div className="flex gap-2 self-end mt-md">
                    <Button onClick={props.onCancel}>Cancel</Button>
                    <Button type="primary" onClick={handleCreate}>
                        Submit
                    </Button>
                </div>
            )}
        </div>
    );
};

export default FareConfigServiceType;
