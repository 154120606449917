import { Button, Table, Text } from 'components';
import ListLayout from 'components/layouts/ContentLayout/ListLayout';
import useApi from 'hooks/useApi';
import useFilter from 'hooks/useFilter';
import useMount from 'hooks/useMount';
import React, { useCallback, useState } from 'react';
import { getCustomersTripHistory } from 'services/message.service';
import { columns } from './columns';
import CustomerTripHistoryFilter from './CustomerTripHistoryFilter/CustomerTripHistoryFilter';
import { useNavigate } from 'react-router-dom';
import { Tag, notification } from 'antd';
import moment from 'moment';

const CustomerTripHistory = ({ id }: { id: string | undefined }) => {
    const [data, setData] = useState([]);
    const [isRefreshing, setIsRefreshing] = useState(false);
    const navigate = useNavigate();

    const pageSize = 10;
    const [pagination, setPagination]: any = useState({
        current: 1,
        defaultCurrent: 1,
        defaultPageSize: pageSize,
    });

    const [showMore, setShowMore] = useState(false);

    const { modifyFilters, requestState } = useFilter({
        page_size: pageSize,
        page: 1,
        id,
    });

    const { request, result, loading } = useApi({
        api: getCustomersTripHistory,
    });

    useMount(() => {
        fetchTripHistory({
            id,
            ...requestState,
        });
    });

    const fetchTripHistory = useCallback(
        async (requestState: {}) => {
            try {
                const result = await request(requestState);
                const d = result.data.data;
                setShowMore(d?.history?.length >= pageSize);
                let trips = d?.history?.map((c: any, index: number) => {
                    return {
                        ...c,
                        key: index,
                    };
                });

                setData(trips);
                let pagination = d.pagination;
                let p = {
                    ...pagination,
                    current: pagination.page,
                    defaultCurrent: 1,
                    pageSize: pagination.page_size,
                    total: pagination.total_count,
                };
                setPagination(p);
                if (result.error) {
                    notification.error({
                        message: 'Error',
                        description: "Error fetching customer's trip history. Please try again later.",
                        duration: 3,
                    });
                }
            } catch (error) {
                throw error;
            }
        },
        [request, setShowMore, pageSize],
    );

    const onTableChange = useCallback(
        async (pagination: any, filters: any, sorter: any) => {
            const { current, pageSize } = pagination;
            const { field, order } = sorter;
            const { requestState } = await modifyFilters({
                page_size: pageSize,
                page: current,
                sort_key: field,
                sort_by: order == 'ascend' ? 'asc' : 'desc',
            });
            await fetchTripHistory(requestState || {});
        },
        [fetchTripHistory, modifyFilters],
    );

    const onFilterSubmit = async (filter: any) => {
        modifyFilters({
            status: filter.status,
            trip_date: filter.trip_date,
            start_date: filter.date_range[0],
            end_date: filter.date_range[1],
        });
        await fetchTripHistory({
            ...requestState,
            page: 1,
            status: filter.status,
            trip_date: filter.trip_date,
            start_date: filter.date_range[0],
            end_date: filter.date_range[1],
        });
    };

    const onRefresh = useCallback(async () => {
        setIsRefreshing(true);
        await fetchTripHistory({
            id,
            ...requestState,
        });
        setIsRefreshing(false);
    }, [requestState, fetchTripHistory, id]);

    const columnsWithClassName = columns.map(column => {
        if (column.key === 'trip_id') {
            return { ...column, className: 'trip-id-cell' };
        } else if (column.key === 'action') {
            return { ...column, className: 'action-cell' };
        }
        return column;
    });

    const formatStatus = (trip: any) => {
        return (
            <div>
                {trip?.status == 'complete' && (
                    <Tag color="green" className="uppercase">
                        {trip.status?.replaceAll('_', ' ')}
                    </Tag>
                )}
                {(trip?.status == 'finding_driver' ||
                    trip?.status == 'driver_otw_pickup' ||
                    trip?.status == 'driver_arrived_pickup' ||
                    trip?.status == 'driver_otw_destination' ||
                    trip?.status == 'driver_arrived_destination') && (
                    <Tag color="geekblue" className="uppercase">
                        {trip?.status?.replaceAll('_', ' ')}
                    </Tag>
                )}
                {trip?.status?.indexOf('driver_not_found') > -1 && (
                    <Tag color="volcano" className="uppercase">
                        {trip?.status?.replaceAll('_', ' ')}
                    </Tag>
                )}
                {trip?.status?.indexOf('canceled') > -1 && (
                    <Tag color="red" className="uppercase">
                        {trip?.status?.replaceAll('_', ' ')}
                    </Tag>
                )}
            </div>
        );
    };
    return (
        <div>
            <ListLayout
                title="Trips History"
                actionComponent={
                    <div>
                        <span style={{ color: 'rgba(0, 0, 0, 0.45)', marginRight: '16px' }}>
                            Please refresh to obtain the latest status of ongoing trips{' '}
                        </span>
                        <Button onClick={onRefresh} type="primary">
                            Refresh
                        </Button>
                    </div>
                }
            >
                <Table
                    className="custom-table-hover"
                    loading={(loading && pagination.current === 1) || isRefreshing}
                    columns={columnsWithClassName}
                    onRow={record => ({
                        onClick: event => {
                            const target = event.target as HTMLElement;
                            const isRestrictedCell = target.closest('.trip-id-cell') || target.closest('.action-cell');
                            if (!isRestrictedCell) {
                                navigate(`/trips/${record.trip_id}`);
                            }
                        },
                        style: { cursor: 'pointer' },
                    })}
                    dataSource={data}
                    pagination={{
                        ...pagination,
                        showSizeChanger: true,
                        showQuickJumper: true,
                        showTotal: (total: any) => `${total?.toLocaleString()} items`,
                    }}
                    onChange={onTableChange}
                    expandable={{
                        expandedRowRender: record => (
                            <Table
                                className="mx-md"
                                locale={{ emptyText: 'No Past Driver(s) Info' }}
                                columns={[
                                    {
                                        title: 'Driver ID',
                                        key: 'driver_id',
                                        render: record => {
                                            return (
                                                <Text type="uuid" className="w-fit">
                                                    {record.driver_id}
                                                </Text>
                                            );
                                        },
                                    },
                                    {
                                        title: 'Updated at',
                                        key: 'updated_at',
                                        render: record => {
                                            return (
                                                <div>
                                                    {record?.updated_at ? (
                                                        <span className="underline decoration-dotted cursor-pointer">
                                                            {moment
                                                                .utc(record?.updated_at)
                                                                .add(8, 'hour')
                                                                .format('MMMM DD, YYYY hh:mm:ss A')}
                                                        </span>
                                                    ) : (
                                                        <span>-</span>
                                                    )}
                                                </div>
                                            );
                                        },
                                    },
                                    {
                                        title: 'Status',
                                        key: 'status',
                                        render: record => {
                                            return formatStatus(record);
                                        },
                                    },
                                ]}
                                dataSource={record.past_driver_info}
                            />
                        ),
                        // rowExpandable: record => record.past_driver_info?.length > 0,
                    }}
                />
            </ListLayout>

            <style>{`
                .custom-table-hover .ant-table-tbody > tr:hover > td {
                    background-color: #e6f7ff !important;
                    cursor: pointer;
                }
            `}</style>
        </div>
    );
};

export default CustomerTripHistory;
