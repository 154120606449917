import { Card, Input, Select, Table, Text } from 'components/basic';
import useApi from 'hooks/useApi';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { generateVouchersRequest,getVoucherZip } from 'services/message.service';
import { Alert, Button, Form, InputNumber, Tabs, notification } from 'antd';
import { Option } from 'antd/lib/mentions';
import Modal from 'antd/lib/modal/Modal';
import useModal from 'hooks/useModal';
import { json2csv } from 'json-2-csv';
import { downloadCSVFile } from '../../../services/file.service';
import VoucherRedemptionPage from './VoucherRedemption/VoucherRedemptionPage';
import { AuthUserContext } from 'components/context/AuthUserContext';
import classnames from 'classnames';
import { useLocation } from 'react-router-dom';
type VoucherSource = 'MAYA' | 'GCASH';

interface VoucherValue {
    amount?: number;
    source?: VoucherSource;
    quantity?: number;
}

interface VoucherInputProps {
    value?: VoucherValue;
    onChange?: (value: VoucherValue) => void;
}

const VoucherInput: React.FC<VoucherInputProps> = ({ value = {}, onChange }) => {
    const [amount, setAmount] = useState('100');
    const [source, setSource] = useState<VoucherSource>('MAYA');
    const [quantity, setQuantity] = useState(1);

    const triggerChange = (changedValue: { amount?: number; source?: VoucherSource; quantity?: number }) => {
        onChange?.({
            amount: parseInt(amount || '0', 10),
            source,
            quantity,
            ...changedValue,
        });
    };

    const onAmountChange = (e: any) => {
        const newAmount = parseInt(e || '0', 10);
        if (Number.isNaN(amount)) {
            return;
        }
        if (!('amount' in value)) {
            setAmount(newAmount.toString());
        }
        triggerChange({ amount: newAmount });
    };

    const onSourceChange = (newSource: VoucherSource) => {
        if (!('source' in value)) {
            setSource(newSource);
        }
        triggerChange({ source: newSource });
    };

    const onQuantityChange = (e: any) => {
        const newQuantity = parseInt(e || '0', 10);
        if (Number.isNaN(quantity)) {
            return;
        }
        if (!('quantity' in value)) {
            setQuantity(newQuantity);
        }
        triggerChange({ quantity: newQuantity });
    };

    return (
        <div className='mt-3'>
            <Form.Item label="Amount" name="amount" rules={[{ required: true }]}>
                <Select
                    value={value.amount || '100'}
                    onChange={onAmountChange}
                    style={{ width: 120 }}
                >
                    <Option value="100">PHP 100</Option>
                    <Option value="250">PHP 250</Option>
                    <Option value="500">PHP 500</Option>
                    <Option value="1000">PHP 1000</Option>
                </Select>
            </Form.Item>

            <Form.Item label="Source" rules={[{ required: true }]} name="source">
                <Select value={value.source || source} onChange={onSourceChange}>
                    <Option value="MAYA">Maya</Option>
                    <Option value="GCASH">GCash</Option>
                </Select>
            </Form.Item>

            <Form.Item
                label="Quantity"
                name="quantity"
                rules={[
                    {
                        type: 'number',
                        min: 1,
                        message: 'Quantity must be greater than 0',
                    },
                    {
                        type: 'number',
                        max: 10000,
                        message: 'Quantity must be less than 10,000',
                    },
                    {
                        required: true,
                        message: 'This field is required.',
                    },
                ]}
            >
                <InputNumber
                    className="text-right"
                    style={{ width: '25%' }}
                    type="number"
                    min={1}
                    value={value.quantity || quantity}
                    onChange={onQuantityChange}
                />
            </Form.Item>
        </div>
    );
};

type VoucherValues = {
    amount: any;
    quantity: number;
    source: string;
};

const VouchersPage = () => {
    const [voucherValues, setVoucherValues] = useState<VoucherValues | null>(null);
    const [message, setMessage] = useState('');
    const confirmationModal = useModal();
    const [csv, setCsv] = useState('');
    const [isDownloaded, setIsDownloaded] = useState(false);
    const userRole = useContext(AuthUserContext);
    const { search } = useLocation();
    const jobId = new URLSearchParams(search).get('job_id');

    const { request: requestVoucherZip } = useApi({
        api: getVoucherZip,
    });

    const downloadFile = async (jobId: any) => {
        try {
            const response = await requestVoucherZip({ id: jobId });

            if (!response.ok) {
                notification.error({
                    message: 'Error',
                    description: 'Error downloading the requested vouchers',
                    duration: 3,
                });
                throw new Error('Network response was not ok');
            }

            const blob = await response.blob();
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'vouchers.csv'); // Set the file name

            document.body.appendChild(link);
            link.click();
            link?.parentNode?.removeChild(link); // Clean up
            setIsDownloaded(true);
        } catch (error) {
            notification.error({
                message: 'Error',
                description: 'Error downloading the requested vouchers',
                duration: 3,
            });
        }
    };

    useEffect(() => {
        if (jobId && !isDownloaded) {
            downloadFile(jobId);
        }
        //eslint-disable-next-line
    }, []);

    const {
        request: generateVoucherRequest,
        loading: generatingVouchers,
        result: generateVoucherResult,
    } = useApi({
        api: generateVouchersRequest,
    });

    const handleGenerateVoucher = async (values: any) => {
        confirmationModal.close();
        const { amount, quantity, source } = values || {};

        const res = await generateVoucherRequest({
            amount: parseInt(amount),
            quantity: quantity,
            source: source,
        });

        const { error, fileUrl, vouchers } = res;

        if (!error) {
            setMessage('File generated and uploaded to Google storage bucket as ' + fileUrl);
            const csvData = await json2csv(vouchers);
            setCsv(csvData);

            notification.success({
                message: 'Request Sent Successfully',
                description: 'Your request for vouchers to be generated has been sent to the approver. Please check your Angkas Email for the status.',
                duration: 3,
            });
        } else {
            notification.error({
                message: 'Error',
                description: error.message,
                duration: 3,
            });
        }
    };

    const onFinish = async (values: any) => {
        const tempValues = { ...values };
        confirmationModal.show({
            title: 'Confirm Voucher Details Request',
            okText: 'Confirm and Send',
            children: (
                <div
                    className="flex flex-col gap-2" >
                    <h5 className='mb-0'>Voucher</h5>
                    <div style={{
                        border: '1px solid #B9CCDA',
                        borderRadius: '16px'
                    }}
                        className='pt-0'>
                        <div style={{ borderBottom: '1px solid #B9CCDA', padding: '8px 16px' }} className='flex justify-between items-center'>
                            <span>Source</span>
                            <h5 className='mb-0'>{values.source}</h5>
                        </div>
                        <div style={{ borderBottom: '1px solid #B9CCDA', padding: '8px 16px' }} className='flex justify-between items-center'>
                            <span>Denomination</span>
                            <h5 className='mb-0'>{values.amount}</h5>
                        </div>
                        <div style={{ padding: '8px 16px' }} className='flex justify-between items-center'>
                            <span>Quantity</span>
                            <h5 className='mb-0'>{values.quantity}</h5>
                        </div>
                    </div>
                </div >
            ),

            onOk: () => handleGenerateVoucher(values),
        });
    };
    return (
        <div className="flex flex-col">
            <Text type="heading">Vouchers</Text>
            <Tabs
                defaultActiveKey="1"
                className="gap-2"
                tabBarGutter={50}
                items={[
                    {
                        label: (
                            <div className="flex flex-row gap-0 items-center">
                                <Text className="uppercase">Generate Voucher Codes</Text>
                            </div>
                        ),
                        key: 'voucher_generation',
                        children: <div>
                            <div className="border-dashed border-1 border border-gray-200 p-8 inline-block">
                                <Text className='font-bold' type="title">Generate Voucher Codes</Text>
                                <div className="flex items-center mt-2 mb-2">
                                    <Alert
                                        message="Your request for vouchers to be generated has been sent to the approver. Please check your Angkas email for the status."
                                        showIcon
                                    />
                                </div>
                                <Form
                                    style={{ maxWidth: 500 }}
                                    labelCol={{ span: 4 }}
                                    wrapperCol={{ span: 14 }}
                                    layout="horizontal"
                                    onFinish={onFinish}
                                    initialValues={{
                                        amount: '100',
                                        source: 'MAYA',
                                        quantity: 1,
                                    }}
                                    disabled={generatingVouchers}
                                >
                                    <div className="flex flex-col flex-items gap-2">
                                        <VoucherInput />

                                        <Form.Item className="p-0" style={{ margin: '0' }}>
                                            <Button
                                                type="primary"
                                                loading={generatingVouchers}
                                                disabled={generatingVouchers}
                                                htmlType="submit"
                                            >
                                                <span>Send Request</span>
                                            </Button>
                                        </Form.Item>
                                    </div>

                                </Form>
                            </div>
                        </div>,
                    },
                    {
                        label: (
                            <div className={'flex flex-row gap-0 items-center'}>
                                <Text className={classnames('uppercase', userRole !== 'FINANCE' && 'text-gray-300')}>
                                    Voucher Redemption History
                                </Text>
                            </div>
                        ),
                        key: 'redemption_history',
                        disabled: userRole !== 'FINANCE',
                        children: <VoucherRedemptionPage />,
                    },
                ]}
            />
            <Modal {...confirmationModal} />
        </div>
    );
};

export default VouchersPage;
