import React, { useCallback, useContext, useState } from 'react';
import { Alert, Button, Tag, message } from 'antd';
import { Table } from 'components';
import useMount from 'hooks/useMount';
import useApi from 'hooks/useApi';
import { getDriversList } from 'services/message.service';
import useFilter from 'hooks/useFilter';
import DriversFilter from './DriversFilter.tsx/DriversFilter';
import { useNavigate } from 'react-router-dom';
import { Dropdown, Text } from 'components/basic';
import { DownOutlined } from '@ant-design/icons';
import ListLayout from 'components/layouts/ContentLayout/ListLayout';
import ImageWithFallback from 'components/basic/ImageWithFallback/ImageWithFallback';
import classnames from 'clsx';
import { Accessibility, PagesEnum, getPermissions } from 'services/permission.service';
import { AuthUserContext } from 'components/context/AuthUserContext';

const DriversPage = () => {
    const managerHubUrl = process.env.REACT_APP_MANAGER_HUB_URL;
    const onboardingHubUrl = process.env.REACT_APP_ONBOARDING_HUB_URL;
    const userRole = useContext(AuthUserContext);
    const hasActionAccess = (page: string) => {
        return getPermissions(userRole, page)?.includes(Accessibility.ALL) ||
            getPermissions(userRole, page)?.includes(Accessibility.EDIT)
    }


    const [data, setData] = useState([]);
    const [pagination, setPagination]: any = useState({
        defaultCurrent: 1,
        defaultPageSize: 10,
    });
    const navigate = useNavigate();

    const { modifyFilters, requestState } = useFilter({
        page_size: 10,
        page: 1,
        search_by: 'phone_number',
        search_key: '',
        sort_key: 'created_at',
        sort_by: 'desc',
        account_status: '',
        service_zone: '',
        service_type: '',
    });

    const renderEmail = useCallback((user: any) => {
        if (user.email) {
            return <a href={`mailto:${user.email}`}>{user.email}</a>;
        } else {
            return <p className="m-0 text-gray-500">No Email</p>;
        }
    }, []);

    const viewInManagerHub = (jalopy_id: number) => () => {
        window.open(`${managerHubUrl}/biker/detail/${jalopy_id}`, '_blank');
    };

    const ColumnActions = useCallback(
        (user: any) => {
            return (
                <Dropdown
                    menu={{
                        items: [
                            {
                                label: 'View',
                                key: '1',
                                onClick: () => {
                                    navigate(`/drivers/${user.id}/overview`);
                                },
                            },
                            {
                                label: 'View in Manager Hub',
                                key: '2',
                                disabled: user?.jalopy_id == 0,
                                onClick: () => {
                                    window.open(`${managerHubUrl}/biker/detail/${user?.jalopy_id}`, '_blank');
                                },
                            },
                        ],
                    }}
                >
                    <Button type="ghost">
                        <DownOutlined />
                    </Button>
                </Dropdown>
            );
        },
        [navigate,managerHubUrl],
    );

    const { request, loading } = useApi({
        api: getDriversList,
    });

    const columns = [
        {
            title: 'Driver ID',
            render: (user: any) => (
                <div className="flex items-center">
                    <a href={`/drivers/${user.id}/overview`} target="_blank">
                        {user.public_id}
                    </a>
                </div>
            ),
        },
        {
            title: 'Status',
            render: (user: any) => (
                <div className="flex items-start flex-col">
                    <div className="">
                        {user.account_status == 'ACTIVE' && <Tag color="green">Active</Tag>}
                        {user.account_status == 'DEACTIVATED' && <Tag color="red">Deactivated</Tag>}
                        {user.account_status == 'SUSPENDED' && <Tag color="orange">Suspended</Tag>}
                        {user.account_status == 'BANNED' && <Tag>Banned</Tag>}
                        {user.account_status == 'SHADOW_BANNED' && <Tag>Shadow Banned</Tag>}
                        {user.account_status == 'SHADOW_SUSPENDED' && <Tag color="orange">Shadow Suspended</Tag>}
                    </div>
                </div>
            ),
        },
        {
            title: 'Avatar',
            render: (user: any) => (
                <div className="flex items-center">
                    <ImageWithFallback src={user.avatar_url} firstName={user.first_name} lastName={user.last_name} />
                </div>
            ),
        },
        {
            title: 'First Name',
            key: 'first_name',
            render: (user: any) => (
                <Text className={classnames({ 'text-gray-500': !user.first_name })}>{user.first_name || '-'}</Text>
            ),
        },
        {
            title: 'Last Name',
            key: 'last_name',
            render: (user: any) => (
                <Text className={classnames({ 'text-gray-500': !user.last_name })}>{user.last_name || '-'}</Text>
            ),
        },
        {
            title: 'Phone Number',
            dataIndex: 'phone_number',
            key: 'phone_number',
        },
        {
            title: 'Email',
            dataIndex: 'email',
            key: 'email',
        },
        {
            title: 'MH User ID',
            render: (user: any) => (
                <div className="flex items-center">
                    {user.jalopy_id > 0 ? (
                        <a href="javascript:void(0)" onClick={viewInManagerHub(user.jalopy_id)}>
                            {user.jalopy_id}
                        </a>
                    ) : (
                        <span className="text-gray">-</span>
                    )}
                </div>
            ),
        },
        {
            title: 'Driver Internal ID',
            key: 'driver_id',
            className: 'driver-id-cell',
            render: (user: any) => (
                <div className="flex items-center">
                    <Text type="uuid">{user.id}</Text>
                </div>
            ),
        },
        {
            title: 'SZ',
            id: 'service_zone',
            dataIndex: 'service_zone',
        },
        {
            title: '',
            dataIndex: 'action',
            className: 'action-cell',
            render: (user: any) => ColumnActions(user),
        },
    ];

    useMount(() => {
        fetchDriversList(requestState);
    });

    const fetchDriversList = useCallback(
        async (requestState: {}) => {
            try {
                request(requestState)
                    .then(result => {
                        let d = result.data;
                        if (d?.status == 'success') {
                            let drivers = d.data.drivers.map((c: any) => {
                                return {
                                    ...c,
                                    key: c.id,
                                };
                            });
                            setData(drivers);
                            let pagination = d.data.pagination;
                            let p = {
                                ...pagination,
                                current: pagination.page,
                                defaultCurrent: 1,
                                pageSize: pagination.page_size,
                                total: pagination.total_count,
                            };
                            setPagination(p);
                        } else {
                            message.error("Error in fetching drivers' list");
                        }
                    })
                    .catch((error: any) => {
                        message.error(error.message);
                    });
            } catch (error) {
                throw error;
            }
        },
        [request],
    );

    const onTableChange = useCallback(
        async (pagination: any, filters: any, sorter: any) => {
            const { current, pageSize } = pagination;
            const { field, order } = sorter;

            let sortKey = 'created_at';
            let sortBy = 'desc';
            if (order === 'ascend') {
                sortBy = 'asc';
            } else if (order === 'descend') {
                sortBy = 'desc';
            }
            const { requestState } = await modifyFilters({
                page_size: pageSize,
                page: current,
                sort_key: sortKey,
                sort_by: sortBy,
            });

            await fetchDriversList(requestState || {});
        },
        [fetchDriversList, modifyFilters],
    );


    const onFilterSubmit = async (filter: any) => {
        modifyFilters({
            search_by: filter.field,
            search_key: filter.keyword,
            account_status: filter.account_status,
            service_zone: filter.service_zone,
            service_type: filter.service_type,
        });
        await fetchDriversList({
            ...requestState,
            page: 1,
            search_by: filter.field,
            search_key: filter.field === 'phone_numbers' ? filter.keyword : filter.keyword?.trim(),
            account_status: filter.account_status,
            service_zone: filter.service_zone,
            service_type: filter.service_type,
        });
    };

    const columnsWithClassName = columns.map((column) => {
        if (column.key === 'driver_id') {
            return { ...column, className: 'driver-id-cell' };
        } else if (column.key === 'action') {
            return { ...column, className: 'action-cell' };
        }
        return column;
    });

    return (
        <>
            <ListLayout
                title="Drivers"
                searchComponent={<DriversFilter onFilterSubmit={onFilterSubmit} loading={loading} />}
                actionComponent={
                    <div className="flex">
                        {hasActionAccess(PagesEnum.BIKER_ACTIVATION) && <div>
                            <Button
                                type="primary"
                                onClick={() => {
                                    navigate(`/activate-drivers/upload`);
                                }}
                            >
                                Activate Drivers
                            </Button>
                        </div>}
                    </div>
                }
            >
                <Table
                    className="custom-table-hover"
                    loading={loading}
                    columns={columnsWithClassName}
                    onRow={(record) => ({
                        onClick: (event) => {
                            console.log(record,'test record')
                            const target = event.target as HTMLElement;
                            const isRestrictedCell = target.closest('.driver-id-cell') || target.closest('.action-cell');
                            if (!isRestrictedCell) {
                                navigate(`/drivers/${record.id}/overview`);
                            }
                        },
                        style: { cursor: 'pointer' },
                    })}
                    dataSource={data}
                    pagination={{
                        ...pagination,
                        showSizeChanger: true,
                        showQuickJumper: true,
                        showTotal: (total: any) => `${total?.toLocaleString()} items`,
                    }}
                    scroll={{ x: 1000 }} // fix horizontal
                    onChange={onTableChange}
                />
            </ListLayout>
            <style>{`
                .custom-table-hover .ant-table-tbody > tr:hover > td {
                    background-color: #e6f7ff !important;
                    cursor: pointer;
                }
            `}</style>
        </>
    );
};

export default DriversPage;
