import React, { useCallback, useState, useRef, useMemo } from 'react';
import { Alert, Button, Result, Tag, message } from 'antd';
import { Table, Text } from 'components';
import useMount from 'hooks/useMount';
import useApi from 'hooks/useApi';
import { getCustomerList } from 'services/message.service';
import CustomerFilter from './CustomerFilter.tsx/CustomerFilter';
import useFilter from 'hooks/useFilter';
import ListLayout from 'components/layouts/ContentLayout/ListLayout';
import { useNavigate } from 'react-router-dom';
import { Dropdown } from 'components/basic';
import { DownOutlined, ExportOutlined } from '@ant-design/icons';
import ImageWithFallback from 'components/basic/ImageWithFallback/ImageWithFallback';
import classnames from 'clsx';
import { CSVLink } from 'react-csv';
import moment from 'moment';

const CustomersPage = () => {
    const managerHubUrl = process.env.REACT_APP_MANAGER_HUB_URL;

    const [data, setData] = useState([]);
    const [result, setResult] = useState<any>([]);
    const [fetchingAllCustomers, setFetchingAllCustomers] = useState(false);
    const [pagination, setPagination]: any = useState({
        defaultCurrent: 1,
        defaultPageSize: 10,
    });

    const { request, loading, error } = useApi({
        api: getCustomerList,
    });

    const { request: requestAllCustomers, error: csvError } = useApi({
        api: getCustomerList,
    });

    const { modifyFilters, requestState } = useFilter({
        page_size: 10,
        page: 1,
        search_by: 'phone_numbers',
        search_key: '',
        sort_key: 'first_name',
        sort_by: 'asc',
        account_status: '',
        service_zone: '',
    });

    const navigate = useNavigate();

    const viewInManagerHub = (jalopy_id: number) => () => {
        window.open(`${managerHubUrl}/biker/detail/${jalopy_id}`, '_blank');
    };

    const ColumnActions = (user: any) => {
        return (
            <Dropdown
                menu={{
                    items: [
                        {
                            label: 'View',
                            key: '1',
                            onClick: () => {
                                navigate(`/customers/${user.id}/overview`);
                            },
                        },
                        {
                            label: 'View in Manager Hub',
                            key: '2',
                            disabled: user?.jalopy_id == 0,
                            onClick: () => {
                                window.open(`${managerHubUrl}/customer/detail/${user?.jalopy_id}`, '_blank');
                            },
                        },
                    ],
                }}
            >
                <Button>
                    <DownOutlined />
                </Button>
            </Dropdown>
        );
    };

    const columns = [
        {
            title: 'Customer ID',
            render: (user: any) => (
                <div className="flex items-center">
                    <a href={`/customers/${user.id}/overview`} target="_blank">
                        {user.public_id}
                    </a>
                </div>
            ),
        },
        {
            title: 'Status',
            render: (user: any) => (
                <div className="flex items-start flex-col">
                    <div className="">
                        {user.account_status == 'ACTIVE' && <Tag color="green">Active</Tag>}
                        {user.account_status == 'DEACTIVATED' && <Tag color="red">Deactivated</Tag>}
                        {user.account_status == 'PENDING' && <Tag color="orange">Pending</Tag>}
                    </div>
                </div>
            ),
        },
        {
            title: 'Avatar',
            render: (user: any) => (
                <div className="flex items-center">
                    <ImageWithFallback src={user.avatar_url} firstName={user.first_name} lastName={user.last_name} />
                </div>
            ),
        },
        {
            title: 'First Name',
            key: 'first_name',
            render: (user: any) => (
                <Text className={classnames({ 'text-gray-500': !user.first_name })}>{user.first_name || '-'}</Text>
            ),
        },
        {
            title: 'Last Name',
            key: 'last_name',
            render: (user: any) => (
                <Text className={classnames({ 'text-gray-500': !user.last_name })}>{user.last_name || '-'}</Text>
            ),
        },
        {
            title: 'Phone Number',
            dataIndex: 'phone_number',
            key: 'phone_number',
        },
        {
            title: 'Email',
            render: (user: any) =>
                user.email ? (
                    <a href={`mailto:${user.email}`}>{user.email}</a>
                ) : (
                    <p className="m-0 text-gray-500">No Email</p>
                ),
        },
        {
            title: 'MH User ID',
            render: (user: any) => (
                <div className="flex items-center">
                    {user.jalopy_id > 0 ? (
                        <a href="javascript:void(0)" onClick={viewInManagerHub(user.jalopy_id)}>
                            {user.jalopy_id}
                        </a>
                    ) : (
                        <span className="text-gray">-</span>
                    )}
                </div>
            ),
        },
        {
            title: 'Customer Internal ID',
            key: 'customer-id',
            className: 'customer-id-cell',
            render: (user: any) => (
                <div className="flex items-center">
                    <Text type="uuid">{user.id}</Text>
                </div>
            ),
        },
        {
            title: 'SZ',
            id: 'service_zone',
            dataIndex: 'service_zone',
        },
        {
            title: '',
            dataIndex: 'action',
            className: 'action-cell',
            render: (user: any) => ColumnActions(user),
        },
    ];

    useMount(() => {
        getUsers(requestState);
    });

    const fetchData = useCallback(
        async (requestState: any) => {
            try {
                setFetchingAllCustomers(true);
                const csvResult = await requestAllCustomers({ ...requestState, page_size: 100000 });
                let res = csvResult.data;

                if (res?.status === 'success') {
                    let customers = res.data.customers.map((c: any) => ({
                        ...c,
                        key: c.id,
                    }));

                    const headers = [
                        'public_id',
                        'account_status',
                        'first_name',
                        'last_name',
                        'id',
                        'phone_number',
                        'service_zone',
                    ];

                    const mappedData = customers.map(
                        ({ public_id, account_status, first_name, last_name, id, phone_number, service_zone }: any) => [
                            public_id,
                            account_status,
                            first_name,
                            last_name,
                            id,
                            phone_number,
                            service_zone,
                        ],
                    );

                    setResult([headers, ...mappedData]);
                }
            } catch (error) {
                console.error('Error fetching data:', error);
            } finally {
                setFetchingAllCustomers(false);
            }
        },
        [requestAllCustomers],
    );

    const getUsers = useCallback(
        async (requestState: {}) => {
            try {
                const result = await request(requestState);
                let d = result.data;
                if (d?.status == 'success') {
                    let customers = d.data.customers.map((c: any) => {
                        return {
                            ...c,
                            key: c.id,
                        };
                    });
                    setData(customers);
                    let pagination = d.data.pagination_data;
                    let totalCount = pagination.page * pagination.page_size;
                    const result3 = await request({ ...requestState, page: pagination.page + 3 }); // look for 3 pages forward first
                    let d3 = result3.data;
                    if (d3?.status == 'success') {
                        if (d3.data.customers?.length > 0) {
                            // page+3 exists
                            totalCount = (pagination.page + 3) * pagination.page_size;
                        } else {
                            // page+3 not exists, check page+1
                            const result1 = await request({ ...requestState, page: pagination.page + 1 }); // look for 1 page forward first
                            let d1 = result1.data;
                            if (d1?.status == 'success') {
                                if (d1.data.customers?.length > 0) {
                                    // page+1 exists
                                    totalCount = (pagination.page + 1) * pagination.page_size;
                                }
                            }
                        }
                    } else {
                        // page+3 not exists, check page+1
                        const result1 = await request({ ...requestState, page: pagination.page + 1 }); // look for 1 page forward first
                        let d1 = result1.data;
                        if (d1?.status == 'success') {
                            if (d1.data.customers?.length > 0) {
                                // page+1 exists
                                totalCount = (pagination.page + 1) * pagination.page_size;
                            }
                        }
                    }
                    let p = {
                        current: pagination.page,
                        defaultCurrent: 1,
                        pageSize: pagination.page_size,
                        total: totalCount,
                    };
                    setPagination(p);

                    await fetchData(requestState);
                } else {
                    setData([]);
                    setPagination({});
                    showMessage(`Error: Fetching customers resulted to an error. Please contact the administrator.`);
                }
            } catch (error) {
                setData([]);
                setPagination({});
                showMessage(`Error: ${error}`);
            }
        },
        [request, fetchData],
    );

    const onTableChange = useCallback(
        async (pagination: any, filters: any, sorter: any) => {
            const { current, pageSize } = pagination;
            const { field, order } = sorter;
            const { requestState } = await modifyFilters({
                page_size: pageSize,
                page: current,
                sort_key: field,
                sort_by: order == 'ascend' ? 'asc' : 'desc',
            });
            await getUsers(requestState || {});
        },
        [getUsers, modifyFilters],
    );

    const showMessage = (msg: any, type = 'error') => {
        message.destroy(); // Destroy any existing messages
        if (type === 'error') {
            message.error(msg); // Display the new message
        } else {
            message.info(msg); // Display the new message
        }
    };

    const onFilterSubmit = async (filter: any) => {
        modifyFilters({
            search_by: filter.field,
            search_key: filter.keyword,
            account_status: filter.account_status,
            service_zone: filter.service_zone,
        });
        await getUsers({
            ...requestState,
            page: 1,
            search_by: filter.field,
            search_key: filter.field === 'phone_numbers' ? filter.keyword : filter.keyword?.trim(),
            account_status: filter.account_status,
            service_zone: filter.service_zone,
        });
    };

    const currentDateTime = moment().format('YYYY-MM-DD-HHmmss');

    const columnsWithClassName = columns.map((column) => {
        if (column.key === 'customer-id') {
            return { ...column, className: 'customer-id-cell' };
        } else if (column.key === 'action') {
            return { ...column, className: 'action-cell' };
        }
        return column;
    });

    return (
        <>
            <ListLayout
                searchComponent={
                    <CustomerFilter onFilterSubmit={onFilterSubmit} setPagination={setPagination} loading={loading} />
                }
                title="Customers"
                actionComponent={
                    <div className="flex justify-between items-center gap-2">
                        {!loading &&
                            requestState.search_by === 'phone_numbers' &&
                            requestState.search_key?.length >= 1 &&
                            !error && (
                                <Text>
                                    {`${pagination?.total || 0} match${pagination?.total === 1 ? '' : 'es'} among ${requestState.search_key?.length || 0
                                        } phone number${requestState.search_key?.length === 1 ? '' : 's'} searched.`}
                                </Text>
                            )}
                        {requestState.search_by === 'phone_numbers' && requestState.search_key?.length >= 1 && !loading && (
                            <Button type="primary" disabled={fetchingAllCustomers || csvError || error}>
                                <CSVLink
                                    className="downloadbtn"
                                    filename={`filter-results-${currentDateTime}.csv`}
                                    data={result}
                                >
                                    <ExportOutlined />
                                    {fetchingAllCustomers ? ' Preparing CSV...' : ' Export to CSV'}
                                </CSVLink>
                            </Button>
                        )}
                    </div>
                }
            >
                <Table
                    className="custom-table-hover"
                    loading={loading}
                    columns={columnsWithClassName}
                    onRow={(record) => ({
                        onClick: (event) => {
                            console.log(record, 'test record')
                            const target = event.target as HTMLElement;
                            const isRestrictedCell = target.closest('.customer-id-cell') || target.closest('.action-cell');
                            if (!isRestrictedCell) {
                                navigate(`/customers/${record.id}/overview`);
                            }
                        },
                        style: { cursor: 'pointer' },
                    })}
                    dataSource={data}
                    pagination={{
                        ...pagination,
                        showSizeChanger: true,
                    }}
                    onChange={onTableChange}
                />
            </ListLayout>
            <style>{`
            .custom-table-hover .ant-table-tbody > tr:hover > td {
                background-color: #e6f7ff !important;
                cursor: pointer;
            }
        `}</style>
        </>
    );
};

export default CustomersPage;
