import { Button, Table, Text, CustomNotification } from 'components';
import ListLayout from 'components/layouts/ContentLayout/ListLayout';
import useApi from 'hooks/useApi';
import useFilter from 'hooks/useFilter';
import useMount from 'hooks/useMount';
import React, { useCallback, useContext, useState } from 'react';
import {
    adjustBalance,
    getDriversWalletOperator,
    getTransactionsList,
} from 'services/message.service';
import {
    WalletOutlined,
    DollarOutlined,
    PlusCircleOutlined,
} from '@ant-design/icons';
import { Alert, Form, Input, Modal, Skeleton } from 'antd';
import columns from './columns';
import { formatNumberToAmount } from 'services/number.service';
import { useAuth0 } from '@auth0/auth0-react';
import { AuthUserContext } from 'components/context/AuthUserContext';
import { Accessibility, PagesEnum, getPermissions } from 'services/permission.service';
import useModal from 'hooks/useModal';

type OffsetType = { defaultOffset: string; previousOffset: any };

const DriverWallet = ({ id }: { id: string | undefined }) => {
    const { getIdTokenClaims, getAccessTokenSilently } = useAuth0();
    const [data, setData] = useState([]);
    const [hasMore, setHasMore] = useState(false);
    const [claims, setClaims] = React.useState<any>(null);
    const [isUpdateOffset, setIsUpdateOffset] = useState(true);
    const [count, setCount] = useState(0);
    const userRole = useContext(AuthUserContext);
    const [form] = Form.useForm();
    const [isFormDirty, setIsFormDirty] = useState(false);
    const [disableSave, setDisableSave] = useState(true);
    const adjustBalanceModal = useModal();
    const hasActionAccess = (page: string) => {
        return getPermissions(userRole, page)?.includes(Accessibility.ALL) ||
            getPermissions(userRole, page)?.includes(Accessibility.EDIT)
    }

    const [offset, setOffset] = useState<OffsetType>({
        defaultOffset: '',
        previousOffset: [],
    });

    const { requestState } = useFilter({
        page_size: 10,
        user_id: id,
        limit: 10,
        after: offset.defaultOffset,
    });

    const { request, result, loading } = useApi({
        api: getDriversWalletOperator,
    });

    const { request: requestTransactions, loading: fetching } = useApi({
        api: getTransactionsList,
    });

    const { request: requestAdjustBalance, loading: adjusting } = useApi({
        api: adjustBalance,
    });

    useMount(() => {
        fetchDriverWallet(requestState);
        fetchDriverWalletTransactions({
            ...requestState,
            after: 0,
        });
        const detectUser = async () => {
            const accessToken = await getAccessTokenSilently();
            if (accessToken) {
                getIdTokenClaims().then((claims) => {
                    setClaims(claims);
                });
            }
        };
        detectUser();
    });

    const handleAdjustBalance = async ({
        type,
    }: {
        type: string;
    }) => {
        try {
            form.validateFields().then(async values => {
                const { reason, amount } = values || {};
                const res = await requestAdjustBalance({
                    body: {
                        driver_id: `${id}`,
                        wallet_type: type.toUpperCase(),
                        direction: 'CREDIT',
                        amount: parseFloat(amount),
                        reason: reason
                    },
                });
    
                if (res.status === 'SUCCESS') {
                    CustomNotification({
                        type: 'success',
                        message: 'Success',
                        description: `${type} Balance updated successfully.`,
                    });
                    fetchDriverWallet({ user_id: id, requestState });
                    fetchDriverWalletTransactions({ user_id: id, requestState });
                } else {
                    if (res?.error?.errorCode === "WALLET_MANUAL_ADJUSTMENT_EXCEED_LIMIT") {
                        let errMsg = <span>Oops! Looks like you've hit the max top-up limit of Php <b>15,000</b> today. Please come back tomorrow</span>
                        CustomNotification({
                            type: 'error',
                            message: 'Error',
                            description: errMsg,
                        });
                    }
                    else {
                        CustomNotification({
                            type: 'error',
                            message: 'Error',
                            description: `Error Saving ${type} Balance: ` + res.error?.message,
                        });
                    }
                }
                adjustBalanceModal.close();
                form.resetFields();
                setIsFormDirty(false);
            });
        } catch (error) {
            throw error;
        }
    };

    const fetchDriverWalletTransactions = useCallback(
        async (requestState: {}, isNext?: boolean) => {
            try {
                const result = await requestTransactions(requestState);
                if (result) {
                    let d = result.data;
                    let transaction = d?.transactions.map((c: any) => {
                        return {
                            ...c,
                            key: c.id,
                        };
                    });
                    let previousOffsetTemp: Array<string>;
                    let defaultOffsetTemp: string;
                    // always change previousOffset value before changing defaultOffset value
                    if (transaction) {
                        if (isNext == null || isNext == undefined) {
                            previousOffsetTemp = [
                                ...offset.previousOffset,
                                offset.defaultOffset,
                            ];
                            setOffset({
                                defaultOffset: transaction.slice(-1)?.[0]?.ledger_id,
                                previousOffset: previousOffsetTemp,
                            });
                        } else {
                            if (isNext) {
                                // Push defualtOffset into previousOffset array
                                previousOffsetTemp = [
                                    ...offset.previousOffset,
                                    offset.defaultOffset,
                                ];
                                defaultOffsetTemp = transaction.slice(-1)?.[0].ledger_id;
                                setOffset({
                                    defaultOffset: defaultOffsetTemp,
                                    previousOffset: previousOffsetTemp,
                                });
                            } else {
                                // Remove latest previousOffset element from previousOffset array
                                previousOffsetTemp = [...offset.previousOffset].slice(
                                    0,
                                    offset.previousOffset.length - 1
                                );
                                defaultOffsetTemp =
                                    offset.previousOffset[offset.previousOffset.length - 1];
                                setOffset({
                                    defaultOffset: defaultOffsetTemp,
                                    previousOffset: previousOffsetTemp,
                                });
                            }
                        }
                    }
                    setData(transaction);
                    setHasMore(d?.has_more);
                }
                if (result.error) {
                    const { status, message } = result.error;
                    if (status >= 500 && status <= 599) {
                        CustomNotification({
                            type: 'error',
                            message: 'Error',
                            description: message ? message : ` ${status} Something went wrong!`,
                        });
                    }
                }
            } catch (error) {
                throw error;
            }
        },
        [requestTransactions, offset]
    );

    const fetchDriverWallet = useCallback(
        async (requestState: {}) => {
            try {
                await request(requestState);
            } catch (error) {
                throw error;
            }
        },
        [request]
    );

    const { cash, credit } = result?.data || {};
    
    const renderAdjustCashBalance = () => {
        const hasAccess = hasActionAccess(PagesEnum.DRIVERS) || hasActionAccess(PagesEnum.WALLET_HISTORY) || hasActionAccess(PagesEnum.TOP_UP_AND_DEDUCTIONS)
        if (!hasAccess) {
            return null;
        } else {
            return <Button disabled={loading} onClick={() => {
                adjustBalanceModal.show({
                    title: 'Manual Adjustment for Cash Balance',
                    okText: 'Save',
                    onOk: () => handleAdjustBalance({ type: 'Cash' }),
                    children: <div>
                        <Form name='adjustCashBalanceForm' layout='vertical' form={form}
                            onFieldsChange={(_, allFields) => {
                                setIsFormDirty(true);
                                let hasError = false;
                                allFields.forEach((field) => {
                                    if (field.errors && field.errors.length > 0 || !field.touched) {
                                        hasError = true;
                                    }
                                });
                                setDisableSave(hasError);
                            }}>
                            <Form.Item
                                label="Cash Balance"
                                name="amount"
                                rules={[{ required: true, message: 'Cash Balance Amount is required.'}, 
                                {
                                    type: 'number',
                                    min: 0,
                                    message: 'Cash Balance should be a number.',
                                    transform: (value) => {
                                        if (value !== undefined && value !== '') {
                                            const parsedValue = parseFloat(value);
                                            if (
                                                isNaN(parsedValue) ||
                                                value !== parsedValue.toString()
                                            ) {
                                                return NaN; // Return NaN for non-numeric input
                                            }
                                            return parsedValue;
                                        }
                                        
                                        return parseFloat(value);
                                    },
                                }]}
                            >
                                <Input
                                    disabled={adjusting}
                                    prefix={
                                        <span className="uppercase text-gray">Php</span>
                                    }
                                />
                            </Form.Item>
                            <Form.Item
                                label="Reason"
                                name="reason"
                                rules={[{ required: true, message: 'Reason is required.' }]}
                            >
                                <Input.TextArea rows={2} placeholder="Enter reason." 
                                    autoSize
                                    showCount
                                    maxLength={240}
                                />
                            </Form.Item>
                        </Form>
                    </div>
                })
            }}>
                    <PlusCircleOutlined />
                    Add Manual Adjustment
                </Button>
        }
    };

    const renderAdjustCreditBalance = () => {
        const hasAccess = hasActionAccess(PagesEnum.DRIVERS) || hasActionAccess(PagesEnum.WALLET_HISTORY) || hasActionAccess(PagesEnum.TOP_UP_AND_DEDUCTIONS)
        if (!hasAccess) {
            return null;
        } else {
            return <Button disabled={loading} onClick={() => {
                adjustBalanceModal.show({
                    title: 'Manual Adjustment for Credit Balance',
                    okText: 'Save',
                    onOk: () => handleAdjustBalance({ type: 'Credit' }),
                    children: <div>
                        <Form name='adjustCreditBalanceForm' layout='vertical' form={form}
                            onFieldsChange={(_, allFields) => {
                                setIsFormDirty(true);
                                let hasError = false;
                                allFields.forEach((field) => {
                                    if (field.errors && field.errors.length > 0 || !field.touched) {
                                        hasError = true;
                                    }
                                });
                                setDisableSave(hasError);
                            }}>
                            <Form.Item
                                label="Credit Balance"
                                name="amount"
                                rules={[{ required: true, message: 'Credit Balance Amount is required.' },
                                {
                                    type: 'number',
                                    min: 0,
                                    message: 'Cash Balance should be a number.',
                                    transform: (value) => {
                                        if (value !== undefined && value !== '') {
                                            const parsedValue = parseFloat(value);
                                            if (
                                                isNaN(parsedValue) ||
                                                value !== parsedValue.toString()
                                            ) {
                                                return NaN; // Return NaN for non-numeric input
                                            }
                                            return parsedValue;
                                        }
                                        
                                        return parseFloat(value);
                                    },
                                }]}
                            >
                                <Input
                                    disabled={adjusting}
                                    prefix={
                                        <span className="uppercase text-gray">Php</span>
                                    }
                                />
                            </Form.Item>
                            <Form.Item
                                label="Reason"
                                name="reason"
                                rules={[{ required: true, message: 'Reason is required.' }]}
                            >
                                <Input.TextArea rows={2} placeholder="Enter reason." 
                                    autoSize
                                    showCount
                                    maxLength={240}
                                />
                            </Form.Item>
                        </Form>
                    </div>
                })
            }}>
                <PlusCircleOutlined />
                Add Manual Adjustment
            </Button>
        }
    };
    const nextpage = () => {
        setCount(count + 1);
        if (!isUpdateOffset) {
            setCount(count - 1);
            setIsUpdateOffset(true);
        }

        fetchDriverWalletTransactions(
            { ...requestState, after: offset.defaultOffset },
            true
        );
    };

    const previouspage = () => {
        setIsUpdateOffset(false);
        fetchDriverWalletTransactions(
            {
                ...requestState,
                after: (offset.previousOffset[offset.previousOffset.length - 2] < 1) ? 0 : offset.previousOffset[offset.previousOffset.length - 2],
            },
            false
        );
    };

    return (
        <div className="flex flex-col gap-4">
            {claims?.user_role != 'SUPER_ADMIN' && (
                <Alert
                    message={
                        <div>
                            Wallet's cash and credit balance can only be adjusted by a
                            Super Admin account.
                        </div>
                    }
                    type="warning"
                    showIcon
                />
            )}
            <div className="flex items-center gap-4">
                <div className="flex items-center gap-4 px-4 py-4 rounded bg-slate-50">
                    <WalletOutlined style={{ fontSize: '24px' }} />
                    <div className="flex flex-col gap-2">
                        <div className="flex flex-col">
                            <Text size="text-xs" className="text-gray-400 uppercase">
                                Cash Balance
                            </Text>
                            {!loading && !adjusting ? (
                                <Text
                                    size="text-md"
                                    className="leading-none uppercase"
                                    fontWeight="font-semibold"
                                >
                                    {`Php ${cash
                                        ? formatNumberToAmount(cash?.current_balance)
                                        : ' -'
                                        }`}
                                </Text>
                            ) : 
                                <Skeleton.Button active shape="round" block />
                            }
                        </div>
                        {renderAdjustCashBalance()}
                    </div>
                </div>
                <div className="flex items-center gap-4 px-4 py-4 rounded bg-slate-50">
                    <DollarOutlined style={{ fontSize: '24px' }} />
                    <div className="flex flex-col gap-2">
                        <div className="flex flex-col">
                            <Text size="text-xs" className="text-gray-400 uppercase">
                                Credit Balance
                            </Text>
                            {!loading ? (
                                <Text
                                    size="text-md"
                                    className="leading-none uppercase"
                                    fontWeight="font-semibold"
                                >
                                    {`Php ${credit
                                        ? formatNumberToAmount(
                                            credit?.current_balance
                                        )
                                        : ' -'
                                        }`}
                                </Text>
                            ) : (
                                <Skeleton.Button active shape="round" block />
                            )}
                        </div>
                        {renderAdjustCreditBalance()}
                    </div>
                </div>
            </div>
            <ListLayout title="Wallet Transaction History">
                <Table
                    columns={columns}
                    dataSource={data}
                    loading={fetching}
                    pagination={false}
                />
                {!fetching && data?.length > 0 && (
                    <div className="flex">
                        <div className="flex-1"></div>
                        <div className="flex gap-2">
                            <Button
                                onClick={previouspage}
                                disabled={offset.previousOffset.length < 2}
                            >
                                Previous
                            </Button>
                            <Button onClick={nextpage} disabled={!hasMore}>
                                Next
                            </Button>
                        </div>
                    </div>
                )}
            </ListLayout>
            <Modal {...adjustBalanceModal}
                okButtonProps={{ disabled: !isFormDirty || disableSave }}
                onCancel={() => {
                    adjustBalanceModal.close();
                    form.resetFields();
                    setIsFormDirty(false);
                }}
            />
        </div>
    );
};

export default DriverWallet;
